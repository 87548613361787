import { AxiosRequestConfig } from 'axios';
import { api } from './api.service';

export abstract class BaseApiService {
  protected static async getCsv(
    url: string,
    fileName: string,
    options: AxiosRequestConfig = {}
  ): Promise<void> {
    const res = await api.get(url, {
      responseType: 'blob',
      ...options
    });

    const objectUrl = window.URL.createObjectURL(res.data);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    // Cleanup
    document.body.removeChild(link);
  }
}
